import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/index.html",
    children: [
      {
        path: "/index.html",
        name: "index", // 首页
        component: () => import("../views/index/index.vue"),
      },
    ],
  },
  {
    path: "/product.html",
    redirect: "/index.html",
  },
  {
    path: "/autoparts.html",
    redirect: "/index.html",
  },
  {
    path: "/mechanics.html",
    redirect: "/index.html",
  },
  {
    path: "/about",
    component: Layout,
    redirect: "/about.html",
    children: [
      {
        path: "/about.html",
        name: "about", // 关于我们
        component: () => import("../views/about/index.vue"),
      },
    ],
  },
  {
    path: "/join",
    component: Layout,
    redirect: "/join.html",
    children: [
      {
        path: "/join.html",
        name: "join", // 代理合作
        component: () => import("../views/join/index.vue"),
      },
    ],
  },
  {
    path: "/mediaCenter",
    component: Layout,
    redirect: "/mediaCenter.html",
    children: [
      {
        path: "/mediaCenter.html",
        name: "mediaCenter", // 媒体中心
        component: () => import("../views/mediaCenter/index.vue"),
      },
    ],
  },
  {
    path: "/mediaDetail",
    component: Layout,
    redirect: "/mediaDetail.html",
    children: [
      {
        path: "/mediaDetail.html",
        name: "mediaDetail", // 媒体详情
        component: () => import("../views/mediaCenter/detail.vue"),
      },
    ],
  },
  {
    path: "/render.html",
    name: "render", // 大屏
    component: () => import("../views/bigScreen/render.vue"),
  },
  {
    path: "/agreement.html",
    name: "agreement", // agreement
    component: () => import("../views/oldUse/agreement.vue"),
  },
  {
    path: "/contact.html",
    name: "contact", // contact
    component: () => import("../views/oldUse/contact.vue"),
  },
  {
    path: "/explain.html",
    name: "explain", // explain
    component: () => import("../views/oldUse/explain.vue"),
  },
  {
    path: "/privacy.html",
    name: "privacy", // privacy
    component: () => import("../views/oldUse/privacy.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  // mode: "hash",
  routes,
});

export default router;
