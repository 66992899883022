import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dataV from "@jiaminghi/data-view";
// 按需引入vue-awesome图标
// import Icon from "vue-awesome/components/Icon";
// import "vue-awesome/icons/chart-bar.js";
// import "vue-awesome/icons/chart-area.js";
// import "vue-awesome/icons/chart-pie.js";
// import "vue-awesome/icons/chart-line.js";
// import "vue-awesome/icons/align-left.js";

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import '@mdi/font/css/materialdesignicons.css'
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify);

// 引入全局css
import "./assets/scss/style.scss";

//引入echart
//4.x 引用方式
import echarts from "echarts";
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

Vue.prototype.$http = axios

// 全局注册
// Vue.component("icon", Icon);
Vue.use(dataV);

import MetaInfo from 'vue-meta-info'  // tdk插件
Vue.use(MetaInfo)

new Vue({
  router,
  store,
  vuetify: new Vuetify({
    icons: {
      iconfont: "md",
    },
  }),
  render: (h) => h(App),
}).$mount("#app");
