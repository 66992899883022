<template>
  <div class="main-container">
    <div>
      <navbar />
    </div>
    <app-main />
    <app-footer />
  </div>
</template>

<script>
import { Navbar, AppMain, AppFooter } from './components'

export default {
  name: 'Layout',
  components: {
    Navbar,
    AppMain,
    AppFooter
  },
  computed: {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped></style>