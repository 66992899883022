import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // requestUrl: 'https://api-test.peiyou.co'
    requestUrl: 'https://api.peiyou.co'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
