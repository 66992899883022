<template>
  <router-view :key="key" />
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style scoped></style>

<style lang="scss"></style>
