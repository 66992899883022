<template>
  <div class="navbar" :style="haveBg ? 'background-color: #01132b' : 'background-color: transparent'">
    <div class="w1200">
      <div class="left fl">
        <a href="/">
          <h1 class="title">配友</h1>
          <img src="@/assets/images/logo_pc.png" title="配友" />
        </a>
      </div>
      <div class="right fr">
        <ul>
          <li :class="active == 'index' ? 'active' : ''"><a href="/">首页</a></li>
          <li>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on">产品功能</a>
              </template>
              <v-list nav>
                <v-list-item-group>
                  <v-list-item v-for="(item, index) in items" :key="index" @click="goIndex(item)">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </li>
          <li :class="active == 'join' ? 'active' : ''"><a href="/join">代理合作</a></li>
          <li :class="active == 'mediaCenter' ? 'active' : ''"><a href="/mediaCenter">媒体中心</a></li>
          <li :class="active == 'about' ? 'active' : ''"><a href="/about">关于我们</a></li>
          <li><a href="tel:400-887-5190">
              <img class="tel" src="@/assets/images/tel.png" alt="">
            </a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
let scroll = 0;
export default {
  data() {
    return {
      haveBg: false,
      items: [
        {
          title: '小配AI',
          id: '0',
          key: '#four',
        },
        {
          title: '精准获客',
          id: '1',
          key: '#five',
        },
        {
          title: '工商数据',
          id: '2',
          key: '#six',
        },
        {
          title: '短信群发',
          id: '3',
          key: '#seven',
        },
        {
          title: '广告投放',
          id: '4',
          key: '#eight',
        },
        {
          title: '客户管理',
          id: '5',
          key: '#nine',
        },
      ],
      active: '',
      bannerHeight: 420,
    }
  },
  components: {

  },
  computed: {

  },
  watch: {
    $route() {
      this.active = this.$route.name;
      if (this.active == "mediaDetail") {
        this.haveBg = true;
      }
    }
  },
  created() {
    if (this.$route.name !== "index") {
      window.addEventListener('resize', this.getBannerH)
    }
  },
  mounted() {
    this.active = this.$route.name;
    if (this.$route.name !== "mediaDetail") { // 新闻详情有背景
      window.addEventListener('scroll', this.handleScroll)
    } else {
      this.haveBg = true;
    }
    if (this.$route.name !== "index" && this.$route.name !== "mediaDetail") {
      setTimeout(() => {
        this.getBannerH()
      }, 500);
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.getBannerH)
  },
  methods: {
    handleScroll() {
      if (this.active == "mediaDetail") {
        this.haveBg = true;
      } else {
        scroll = document.documentElement.scrollTop || document.body.scrollTop
        if (scroll >= (this.bannerHeight - 58)) {
          this.haveBg = Object.freeze(true);
        } else {
          this.haveBg = Object.freeze(false);
        }
      }
    },
    getBannerH() {
      this.bannerHeight = document.querySelector(".banner").offsetHeight;
    },
    goIndex(item) {
      if (this.$route.name === "index") {
        this.$vuetify.goTo(item.key);
      } else {
        this.$router.push('index.html?href=' + item.key);
      }
    }
  }
}
</script>
<style lang="scss">
// .v-menu__content--fixed {
// top: 58px !important;
// }
</style>
<style lang="scss" scoped>
.title {
  position: absolute;
  left: -99999px;
}

.navbar {
  // transition: 0.5s;
  background-color: transparent;
  width: 100%;
  height: 58px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .w1200 {
    height: 100%;
  }

  .left,
  .right {
    height: 100%;
  }
}

.left {
  a {
    height: 100%;
    display: flex;
    align-items: center;
  }

  img {
    width: 130px;
    height: 40px;
    float: left;
  }
}

.right {
  ul {
    height: 100%;
    display: flex;
    align-items: center;

    li,
    a {
      height: 100%;
      line-height: 58px;
      position: relative;
    }

    li a {
      display: inline-block;
      padding: 0 10px;
      margin: 0 20px;
      font-family: PingFang-SC-Medium;
      font-size: 16px;
      letter-spacing: 2px;
      color: #dddddd;
      display: flex;
      justify-content: center;
      align-items: center;

      img.tel {
        width: 141px;
        height: 17px;
      }
    }

    li.active a {
      font-weight: bold;
      color: #fff;
    }
  }
}

:deep .v-menu__content--fixed {
  top: 58px !important;
}
</style>
