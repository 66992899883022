<template>
  <div class="footer">
    <div class="footer-container w1200 clearfix">
      <div class="left fl">
        <ul class="ul-list">
          <li class="item"><a href="/" class="cur">首页</a></li>
          <li class="item"><a href="/join">代理合作</a></li>
          <li class="item"><a href="/mediaCenter">媒体中心</a></li>
          <li class="item"><a href="/about">关于我们</a></li>
        </ul>
        <p class="banquan">
          增值电信业务经营许可证<a href="https://beian.miit.gov.cn/" target="_blank"> 皖B2-20230190 皖ICP备222003012号</a>&ensp;Copyright
            &copy; {{ time }} 安徽配友软件有限公司 版权所有
        </p>
      </div>

      <div class="right fr">
        <div class="qrcode">
          <img class="" src="@/assets/images/gongzhonghao.png" />
          <p>配友公众号</p>
        </div>
        <div class="qrcode">
          <img class="" src="@/assets/images/kf.png" />
          <p>微信咨询</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myFooter',
  components: {

  },
  computed: {
    time() {
      return new Date().getFullYear()
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 200px;
  background-color: #f8f8f8;
  border-top: 1px solid transparent;

  .footer-container {
    height: 100%;

  }
}

.left,
.right {
  height: 100%;
}

.left {
  .ul-list {
    margin: 78px 0 18px;
    display: flex;
    padding: 0;

    .item {
      padding: 0 10px;
      height: 18px;
      font-size: 16px;
      line-height: 18px;
      border-right: 1px solid #000;
      cursor: pointer;

      a {
        color: #000000;
      }

      &:last-child {
        border-right: 0;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  p {
    font-size: 14px;

    a {
      line-height: 18px;
      color: #000000;
    }
  }
}

.right {
  display: flex;

  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 120px;
      height: 120px;
    }

    p {
      margin-top: 10px;
      height: 16px;
      font-size: 16px;
      line-height: 16px;
      color: #000000;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}
</style>
